<template>
  <v-container>
    <v-sheet>
      <v-row>
        <v-col cols="12">
          <v-card-text class="text-center">
            <p class="mt-5 success--text text-h5">Order Success</p>
            <v-icon class="mb-5 mt-5 text-h1" color="success" large> mdi-checkbox-marked-circle </v-icon>
            <p class="text-h3 black--text"> Thank you for your Order </p>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn :to="{ name: routeMap.products.name }" color="success">Shop more</v-btn>
            <v-btn :to="{ name : routeMap.user.invoice.name, params: {invoice: getOrderId} }" color="secondary">View Order</v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import { storeHelper } from '@/utils/helper';
import { CART_CLEAR, cartNameSpace } from '@/store/modules/cart/types';
import { mapActions } from 'vuex';

import routeMap from '@/utils/routeMap';


export default {
  name: 'OrderSuccess',
  data: ()=> ({
    order: undefined,
    routeMap: routeMap,
  }),
  computed: {
    getOrderId(){
      return this.order?.id
    }
  },
  methods: {
    ...mapActions({
      clearOrderDetails: storeHelper(cartNameSpace, CART_CLEAR)
    }),
  },
  async beforeMount() {

    let orderStatus = this.$route?.params?.order;
    let orderData = this.$route?.params?.orderData;
    this.order = orderData

    if (orderStatus !== 'success') {
      return this.$router.push({ name: 'Home' });
    }

    await this.clearOrderDetails();
  }
};
</script>

<style scoped>

</style>
